import Request from '@/lib/Request'
// const http = new Request('https://cubewebapi.51ylk.com', 10000)
// const http = new Request('http://192.168.1.65:8090', 3000)
const http = new Request(process.env.VUE_APP_URL)

// 查模板分类
export const getTemplateClass = params => {
  return http.post('/cube-client/home/queryHomeClassification', params)
}
// 查模板列表
export const getTemplateList = params => {
  return http.post('/cube-client/home/planar/queryPage', params)
}
// 查印刷列表
export const getPrintList = params => {
  return http.post('/cube-client/home/product/queryPage', params)
}
// 查营销活动
export const getActiveList = params => {
  return http.post('/cube-client/activity/temple/index/page', params)
}