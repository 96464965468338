import { queryTreeTags, attrQuery, classificationqueryTree, holidayquery } from '@/api/cubeclient'
import Tool from '@/lib/tool'
let SearchParamsInfo = {
  classifications: [],
  holidays: [],
  colorAttrs: [],
  styleAttrs: [],
  tags: [],
  selectedClassifications: [],
  selectedColorAttrs: [],
  selectedStyleAttrs: [],
  selectedHolidays: [],
  selectedTags: []
}
let parentId
class SearchParamsUtil {
  static initParam (keywords) {
    SearchParamsInfo.classifications = []
    SearchParamsInfo.colorAttrs = []
    SearchParamsInfo.styleAttrs = []
    SearchParamsInfo.holidays = []
    SearchParamsInfo.tags = []
    SearchParamsInfo.selectedClassifications = []
    SearchParamsInfo.selectedColorAttrs = []
    SearchParamsInfo.selectedStyleAttrs = []
    SearchParamsInfo.selectedTags = []
    SearchParamsInfo.selectedHolidays = []
    SearchParamsInfo.keywords = keywords
    SearchParamsInfo.useKeywords = !Tool.isEmpty(keywords)
  }

  static async queryClassificationsById (id, init) {
    parentId = id
    let res = await classificationqueryTree({
      "colorAttrs": SearchParamsInfo.selectedColorAttrs,
      "parentId": parentId,
      "styleAttrs": SearchParamsInfo.selectedStyleAttrs,
      "tags": SearchParamsInfo.selectedTags,
      "useKeywords": SearchParamsInfo.useKeywords,
      "keywords": SearchParamsInfo.keywords,
      "holidays": SearchParamsInfo.selectedHolidays
    })
    if (res.data) {
      SearchParamsInfo.classifications = res.data
    }
    if (init) {
      SearchParamsInfo.selectedClassifications = [id]
    }
  }
  static async queryClassifications (init) {
    let res = await classificationqueryTree({
      "colorAttrs": SearchParamsInfo.selectedColorAttrs,
      "parentId": parentId,
      "styleAttrs": SearchParamsInfo.selectedStyleAttrs,
      "tags": SearchParamsInfo.selectedTags,
      "useKeywords": SearchParamsInfo.useKeywords,
      "keywords": SearchParamsInfo.keywords,
      "holidays": SearchParamsInfo.selectedHolidays
    })
    if (res.data) {
      SearchParamsInfo.classifications = res.data
    }
    if (init) {
      SearchParamsInfo.selectedClassifications = [parentId]
    }
  }
  static async getTagListByClassId (id, init) {
    const res = await queryTreeTags(
      {
        "classifications": [id],
        "colorAttrs": SearchParamsInfo.selectedColorAttrs,
        "styleAttrs": SearchParamsInfo.selectedStyleAttrs,
        "parentId": 0,
        "useKeywords": SearchParamsInfo.useKeywords,
        "keywords": SearchParamsInfo.keywords,
        "holidays": SearchParamsInfo.selectedHolidays
      }
    )
    SearchParamsInfo.tags = res.data
    SearchParamsInfo.tags.forEach(item => {
      let childrenList = []
      item.childrenList.forEach(item => {
        childrenList = [...childrenList, ...item.childrenList]
      })
      item.childrenList = childrenList
      if (init) {
        SearchParamsInfo.selectedTags.push(item.id)
      }

    })
    return res.data
  }
  static async getTagList (init) {
    let initSeleted = []
    const res = await queryTreeTags({
      "tags": SearchParamsInfo.selectedTags,
      "classifications": SearchParamsInfo.selectedClassifications,
      "colorAttrs": SearchParamsInfo.selectedColorAttrs,
      "styleAttrs": SearchParamsInfo.selectedStyleAttrs,
      "parentId": 0,
      "useKeywords": SearchParamsInfo.useKeywords,
      "keywords": SearchParamsInfo.keywords,
      "holidays": SearchParamsInfo.selectedHolidays
    })
    SearchParamsInfo.tags = res.data
    SearchParamsInfo.tags.forEach(item => {
      let childrenList = []
      item.childrenList.forEach(item => {
        childrenList = [...childrenList, ...item.childrenList]
      })
      item.childrenList = childrenList
      let selectedItem = childrenList.find((children) => {
        return SearchParamsInfo.selectedTags.findIndex(tag => tag === children.id) !== -1
      })
      if (selectedItem) {
        initSeleted.push(selectedItem.id)
      } else {
        initSeleted.push(item.id)
      }

    })
    // if (init) {
    SearchParamsInfo.selectedTags = initSeleted
    // }
    return res.data
  }
  static async querHoliday (init) {
    let params = {
      "classifications": SearchParamsInfo.selectedClassifications,
      "colorAttrs": SearchParamsInfo.selectedColorAttrs,
      "styleAttrs": SearchParamsInfo.selectedStyleAttrs,
      "tags": SearchParamsInfo.selectedTags,
      "useKeywords": SearchParamsInfo.useKeywords,
      "keywords": SearchParamsInfo.keywords,
      "holidays": []
    }
    const holidayRes = await holidayquery(params)
    SearchParamsInfo.holidays = holidayRes.data
    return holidayRes.data
  }
  static async queryColors (init) {
    let params = {
      "attrType": "color",
      "classifications": SearchParamsInfo.selectedClassifications,
      "colorAttrs": [],
      "styleAttrs": SearchParamsInfo.selectedStyleAttrs,
      "tags": SearchParamsInfo.selectedTags,
      "useKeywords": SearchParamsInfo.useKeywords,
      "keywords": SearchParamsInfo.keywords,
      "holidays": SearchParamsInfo.selectedHolidays
    }
    const colorRes = await attrQuery(params)
    SearchParamsInfo.colorAttrs = colorRes.data
    return colorRes.data
  }
  static async queryStyles (init) {
    let params = {
      "attrType": "style",
      "classifications": SearchParamsInfo.selectedClassifications,
      "colorAttrs": SearchParamsInfo.selectedColorAttrs,
      "styleAttrs": [],
      "tags": SearchParamsInfo.selectedTags,
      "useKeywords": SearchParamsInfo.useKeywords,
      "keywords": SearchParamsInfo.keywords,
      "holidays": SearchParamsInfo.selectedHolidays
    }
    const styleRes = await attrQuery(params)
    SearchParamsInfo.styleAttrs = styleRes.data

    return styleRes.data
  }
  static getParams () {
    return SearchParamsInfo
  }
  static async selectClassification (id) {
    SearchParamsInfo.selectedClassifications = [id]
    await SearchParamsUtil.getTagList(true)
    await SearchParamsUtil.querHoliday()
    await Promise.all([
      SearchParamsUtil.queryColors(),
      SearchParamsUtil.queryStyles()])
  }
  static async selectedHoliday (id) {

    SearchParamsInfo.selectedHolidays = [id]
    if (id == null) {
      SearchParamsInfo.selectedHolidays = []
    }
    await SearchParamsUtil.queryClassifications()
    await SearchParamsUtil.getTagList()
    await Promise.all([
      SearchParamsUtil.queryColors(),
      SearchParamsUtil.queryStyles()])
  }
  static async selectTag (id) {
    for (let i = 0; i < SearchParamsInfo.tags.length; i++) {
      let item = SearchParamsInfo.tags[i]
      if (item.id === id) {
        SearchParamsInfo.selectedTags[i] = id
        break
      }
      let index = item.childrenList.findIndex(item => {
        return item.id === id
      })
      if (index != -1) {
        SearchParamsInfo.selectedTags[i] = id
        break
      }
    }
    await SearchParamsUtil.queryClassifications()
    await SearchParamsUtil.getTagList()
    await SearchParamsUtil.querHoliday()
    await Promise.all([
      SearchParamsUtil.getTagList(),
      SearchParamsUtil.queryStyles()])
  }

  static async selectStyle (id) {
    if (id == '') {
      SearchParamsInfo.selectedStyleAttrs = []
    } else {
      SearchParamsInfo.selectedStyleAttrs = [id]
    }
    await SearchParamsUtil.queryClassifications()
    await SearchParamsUtil.getTagList()
    await SearchParamsUtil.querHoliday()
    await Promise.all([
      SearchParamsUtil.getTagList(),
      SearchParamsUtil.queryStyles()])
  }
  static async selectColor (id) {
    if (id == '') {
      SearchParamsInfo.selectedColorAttrs = []
    } else {
      SearchParamsInfo.selectedColorAttrs = [id]
    }
    await SearchParamsUtil.queryClassifications()
    await SearchParamsUtil.getTagList()
    await SearchParamsUtil.querHoliday()
    await Promise.all([
      SearchParamsUtil.getTagList(),
      SearchParamsUtil.queryStyles()])
  }
}

export default SearchParamsUtil