import Request from '@/lib/Request'
// const http = new Request('https://cubewebapi.51ylk.com', 3000)
const http = new Request(process.env.VUE_APP_URL)
// const http = new Request('http://192.168.1.65:19001', 3000)
// 分类
export const selectAllTaxonomy = params => {
  return http.get('/cube_web_api/taxonomy/selectAllTaxonomy', params)
}

// 分页查询平面模板
export const selectListPage = params => {
  return http.post('/cube_web_api/planar/selectListPage', params)
}

// 获取颜色列表
export const colorList = params => {
  return http.get('/cube_web_api/attr/selectAttr/color', params)
}

// 获取风格列表
export const styleList = params => {
  return http.get('/cube_web_api/attr/selectAttr/style', params)
}

// 收藏
export const collection = params => {
  return http.post('/cube_web_api/mine/add', params)
}
// 取消收藏
export const cancelCollection = params => {
  return http.post('/cube_web_api/mine/cancel', params)
}
// 取消收藏
export const getPsdJson = params => {
  return http.get('/cube_web_api/psdjson/get', params)
}
// 生成封面
export const drawCover = params => {
  return http.get('/cube_web_api/planar/drawCover', params)
}
// 下载图片
export const download = params => {
  return http.get('/cube_web_api/planar/download', params)
}

// 查询标签接口
export const selectAllTags = params => {
  return http.post('/cube_web_api/taxonomy/selectAllTags', params)
}
// 在线编辑器——获取最新封面PDF图
export const get = params => {
  return http.get('/cube_web_api/planar/image/get', params)
}

// 在线编辑器——画封面
export const drawBase64Cover = params => {
  return http.post('/cube_web_api/planar/drawBase64Cover', params)
}
