var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index" },
    [
      _c("div", { staticClass: "indexTop" }, [
        _c("div", { staticClass: "leftTitle" }, [
          _c("h1", [_vm._v("创意无限 立方实现")]),
          _c("h2", [_vm._v("让设计更简单 · 让营销更有 · 让印刷更轻松")]),
          _c(
            "div",
            {
              staticClass: "start",
              on: {
                click: function($event) {
                  return _vm.$router.push("/OnlineDesign/Selection")
                }
              }
            },
            [_vm._v("开始使用")]
          )
        ]),
        _c("div", { staticClass: "rightImg" }, [
          _c(
            "video",
            {
              staticClass: "video",
              attrs: {
                autoplay: "autoplay",
                loop: "loop",
                muted: "muted",
                poster: ""
              },
              domProps: { muted: true }
            },
            [
              _c("source", {
                attrs: {
                  src:
                    "https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/video/banner1.mp4",
                  type: "video/mp4"
                }
              })
            ]
          )
        ])
      ]),
      _vm._m(0),
      _c("div", { staticClass: "design" }, [
        _c("h3", { staticClass: "design-title" }, [
          _vm._v("营销活动，高效拉新")
        ]),
        _c("div", { staticClass: "design-content" }, [
          _c("div", { staticClass: "video-area" }, [
            _c(
              "video",
              {
                staticClass: "design-video",
                attrs: {
                  autoplay: "autoplay",
                  loop: "loop",
                  muted: "muted",
                  poster: ""
                },
                domProps: { muted: true }
              },
              [
                _c("source", {
                  attrs: {
                    src:
                      "https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/video/%E8%90%A5%E9%94%80%E8%AE%BE%E8%AE%A1.mp4",
                    type: "video/mp4"
                  }
                })
              ]
            ),
            _vm._m(1)
          ]),
          _c("div", { staticClass: "template-area" }, [
            _vm._m(2),
            _c("div", { staticClass: "template-label" }, [
              _c(
                "ul",
                { staticClass: "template-list" },
                _vm._l(_vm.prizeTypeList, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "template-item",
                      class: { active: index === _vm.currentActive },
                      on: {
                        click: function($event) {
                          return _vm.searchActive(index, item.value)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                0
              )
            ]),
            _c(
              "ul",
              { staticClass: "template-content" },
              _vm._l(_vm.activeList, function(item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "content-item selected",
                    on: {
                      click: function($event) {
                        return _vm.createAct(item.id)
                      },
                      mouseover: function($event) {
                        _vm.selectAct = index
                      },
                      mouseout: function($event) {
                        _vm.selectAct = null
                      }
                    }
                  },
                  [
                    _c("img", { attrs: { src: item.cover, alt: "" } }),
                    _c("p", [_vm._v(_vm._s(item.name))]),
                    _c("div", { staticClass: "price" }, [
                      item.isFree
                        ? _c("span", { staticClass: "sellPrice free" }, [
                            _vm._v("免费")
                          ])
                        : item.templeStatus == 1
                        ? _c("span", { staticClass: "sellPrice" }, [
                            _vm._v("限免")
                          ])
                        : _c("span", { staticClass: "sellPrice" }, [
                            _vm._v(_vm._s("￥" + item.sellingPrice))
                          ]),
                      !item.isFree && item.originalPrice
                        ? _c("span", { staticClass: "orgPrice" }, [
                            _vm._v(_vm._s("￥" + item.originalPrice))
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              {
                staticClass: "template-more",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/MarketingActivities")
                  }
                }
              },
              [
                _vm._v(" 查看更多 "),
                _c("i", { staticClass: "el-icon-sort-up" })
              ]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "design" }, [
        _c("h3", { staticClass: "design-title" }, [
          _vm._v("在线印刷，物美价廉")
        ]),
        _c("div", { staticClass: "design-content" }, [
          _c("div", { staticClass: "video-area" }, [
            _c(
              "video",
              {
                staticClass: "design-video",
                attrs: {
                  autoplay: "autoplay",
                  loop: "loop",
                  muted: "muted",
                  poster: ""
                },
                domProps: { muted: true }
              },
              [
                _c("source", {
                  attrs: {
                    src:
                      "https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/video/%E5%9C%A8%E7%BA%BF%E5%8D%B0%E5%88%B7.mp4",
                    type: "video/mp4"
                  }
                })
              ]
            ),
            _vm._m(3)
          ]),
          _c("div", { staticClass: "template-area" })
        ])
      ]),
      _c("div", { staticClass: "comment" }, [
        _c("h3", [_vm._v("优秀的口碑源于对品质的追求")]),
        _c(
          "div",
          { staticClass: "slideshow" },
          [
            _c(
              "el-carousel",
              { attrs: { "indicator-position": "outside", height: "180px" } },
              _vm._l(_vm.commentList, function(item, index) {
                return _c("el-carousel-item", { key: index }, [
                  _c(
                    "ul",
                    { staticClass: "comment-list" },
                    _vm._l(item, function(value, key) {
                      return _c(
                        "li",
                        { key: key, staticClass: "comment-item" },
                        [
                          _c("div", { staticClass: "comment-title" }, [
                            _c("img", {
                              attrs: {
                                src: value.img,
                                alt: "",
                                width: "45px",
                                height: "45px"
                              }
                            }),
                            _c("p", { staticClass: "name" }, [
                              _vm._v(_vm._s(value.name))
                            ]),
                            _c("p", { staticClass: "post" }, [
                              _vm._v(_vm._s(value.post))
                            ])
                          ]),
                          _c("p", { staticClass: "describe" }, [
                            _vm._v(_vm._s(value.describe))
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ])
              }),
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "index-bottom" }, [
        _vm._m(4),
        _c(
          "div",
          {
            staticClass: "right-btn",
            on: {
              click: function($event) {
                return _vm.$router.push("/OnlineDesign/Selection")
              }
            }
          },
          [_vm._v("开始使用")]
        )
      ]),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "introduce" }, [
          _c("dl", { staticClass: "companyInfo" }, [
            _vm._m(5),
            _vm.show
              ? _c(
                  "dd",
                  {
                    staticClass: "item",
                    on: {
                      click: function($event) {
                        return _vm.join()
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/img/index/icon_factory.png"),
                        alt: ""
                      }
                    }),
                    _vm._v(" 印刷厂入驻 ")
                  ]
                )
              : _vm._e()
          ]),
          _vm._m(6),
          _c("dl", { staticClass: "area" }, [
            _vm._m(7),
            _c("dd", { staticClass: "item" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.$url + "/#/MarketingActivities",
                    target: "_blank"
                  }
                },
                [_vm._v("营销活动")]
              )
            ])
          ]),
          _c("dl", { staticClass: "area" }, [
            _vm._m(8),
            _vm._m(9),
            _c("dd", { staticClass: "item" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.$url + "/#/PrivacyAgreement",
                    target: "_blank"
                  }
                },
                [_vm._v("隐私策略")]
              )
            ]),
            _c("dd", { staticClass: "item" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.$url + "/#/UserAgreement",
                    target: "_blank"
                  }
                },
                [_vm._v("用户协议")]
              )
            ])
          ]),
          _vm._m(10)
        ])
      ]),
      _vm._m(11),
      _c("div", { staticClass: "sidebar" }, [
        _c(
          "ul",
          _vm._l(_vm.sidebarList, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "sidebar-item",
                on: {
                  mouseover: function($event) {
                    _vm.current = index
                  },
                  mouseout: function($event) {
                    _vm.current = null
                  }
                }
              },
              [
                index != 1 || _vm.isShow
                  ? [
                      _c(
                        "a",
                        {
                          attrs: { href: item.href },
                          on: {
                            click: function($event) {
                              return _vm.toTop(index)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: { src: item.icon, title: item.title }
                          }),
                          _c("img", {
                            class: ["over", { active: index === _vm.current }],
                            attrs: {
                              src: item.icon_selected,
                              title: item.title
                            }
                          })
                        ]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          }),
          0
        ),
        _vm.current === 0
          ? _c("div", { staticClass: "qrcode" }, [
              _c("p", { staticClass: "qrcode-text" }, [
                _vm._v("无限立方公众号")
              ]),
              _c("img", {
                staticClass: "public",
                attrs: {
                  src: require("../../assets/infinite_official_account.jpg"),
                  alt: "",
                  title: "无限立方公众号"
                }
              }),
              _c("p", { staticClass: "qrcode-text" }, [_vm._v("客服二维码")]),
              _c("img", {
                staticClass: "public",
                attrs: {
                  src: require("../../assets/wxkf.jpg"),
                  alt: "",
                  title: "客服二维码"
                }
              })
            ])
          : _vm._e()
      ]),
      _c("bind")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "process" }, [
      _c("p", { staticClass: "process-title" }, [
        _vm._v("一站式印刷品解决方案")
      ]),
      _c("img", {
        staticClass: "process-banner",
        attrs: { src: require("../../assets/img/index/process.png"), alt: "" }
      }),
      _c("ul", { staticClass: "process-list" }, [
        _c("li", { staticClass: "process-item" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/img/index/icon_marketing.png"),
              alt: ""
            }
          }),
          _c("h3", [_vm._v("营销活动")]),
          _c("p", [
            _vm._v("主流的活动模板+高效的运营玩法，轻松策划一场爆款活动")
          ])
        ]),
        _c("li", { staticClass: "process-item" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/img/index/icon_QR.png"),
              alt: ""
            }
          }),
          _c("h3", [_vm._v("二维码")]),
          _c("p", [
            _vm._v("二维码联结设计稿和营销活动，让创意与目的更有效地传播")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "design-info" }, [
      _c("li", { staticClass: "design-item active" }, [
        _c("img", {
          staticClass: "design-icon",
          attrs: {
            src: require("../../assets/img/index/icon_play.png"),
            alt: ""
          }
        }),
        _c("div", { staticClass: "design-text" }, [
          _c("h4", [_vm._v("玩法多样化")]),
          _c("p", [_vm._v("多样化的活动玩法，可玩性高")])
        ])
      ]),
      _c("li", { staticClass: "design-item active" }, [
        _c("img", {
          staticClass: "design-icon",
          attrs: {
            src: require("../../assets/img/index/icon_rules.png"),
            alt: ""
          }
        }),
        _c("div", { staticClass: "design-text" }, [
          _c("h4", [_vm._v("自定义规则")]),
          _c("p", [_vm._v("可视化自定义活动规则，高效拉新促活")])
        ])
      ]),
      _c("li", { staticClass: "design-item active" }, [
        _c("img", {
          staticClass: "design-icon",
          attrs: {
            src: require("../../assets/img/index/icon_monitoring.png"),
            alt: ""
          }
        }),
        _c("div", { staticClass: "design-text" }, [
          _c("h4", [_vm._v("可视化监控")]),
          _c("p", [_vm._v("提供主流的电子化奖品，自动发奖，活动数据全程监控")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "area-title" }, [
      _c("span", { staticClass: "text" }, [
        _vm._v("多样化的玩法，丰富的活动模板")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "design-info" }, [
      _c("li", { staticClass: "design-item active" }, [
        _c("img", {
          staticClass: "design-icon",
          attrs: {
            src: require("../../assets/img/index/icon_print.png"),
            alt: ""
          }
        }),
        _c("div", { staticClass: "design-text" }, [
          _c("h4", [_vm._v("印品类型丰富")]),
          _c("p", [_vm._v("丰富的印品类型，满足多种印刷场景")])
        ])
      ]),
      _c("li", { staticClass: "design-item active" }, [
        _c("img", {
          staticClass: "design-icon",
          attrs: {
            src: require("../../assets/img/index/icon_quality.png"),
            alt: ""
          }
        }),
        _c("div", { staticClass: "design-text" }, [
          _c("h4", [_vm._v("品质保障")]),
          _c("p", [_vm._v("大厂承接订单，保证物美价廉、售后无忧")])
        ])
      ]),
      _c("li", { staticClass: "design-item active" }, [
        _c("img", {
          staticClass: "design-icon",
          attrs: {
            src: require("../../assets/img/index/icon_order.png"),
            alt: ""
          }
        }),
        _c("div", { staticClass: "design-text" }, [
          _c("h4", [_vm._v("全程自助下单")]),
          _c("p", [_vm._v("自定义自助下单和帮我下单，让印刷更轻松")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-text" }, [
      _c("h1", [_vm._v("创意无限 立方实现")]),
      _c("p", [_vm._v("让设计更简单 · 让营销更有料 · 让印刷更轻松")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", [
      _c("img", {
        attrs: {
          src: require("../../assets/img/index/logo_bottom.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", { staticClass: "area" }, [
      _c("dt", { staticClass: "item" }, [_c("h4", [_vm._v("无限立方")])]),
      _c("dd", { staticClass: "item" }, [
        _c(
          "a",
          {
            attrs: {
              href: "http://taiheiot.com/about/introduction",
              target: "_blank"
            }
          },
          [_vm._v("公司介绍")]
        )
      ]),
      _c("dd", { staticClass: "item" }, [
        _c(
          "a",
          {
            attrs: { href: "http://taiheiot.com/about/honor", target: "_blank" }
          },
          [_vm._v("企业荣誉")]
        )
      ]),
      _c("dd", { staticClass: "item" }, [
        _c(
          "a",
          {
            attrs: { href: "http://taiheiot.com/about/join", target: "_blank" }
          },
          [_vm._v("加入我们")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", { staticClass: "item" }, [_c("h4", [_vm._v("产品服务")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dt", { staticClass: "item" }, [_c("h4", [_vm._v("帮助中心")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dd", { staticClass: "item" }, [
      _c("a", { attrs: { href: "javascript:void(0)" } }, [_vm._v("帮助文档")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", { staticClass: "area" }, [
      _c("dt", { staticClass: "item" }, [_c("h4", [_vm._v("联系我们")])]),
      _c("dd", { staticClass: "item" }, [
        _c("span", [_vm._v("电话：86-755-86098666")])
      ]),
      _c("dd", { staticClass: "item" }, [
        _c("span", [_vm._v("传真：86-755-86011222")])
      ]),
      _c("dd", { staticClass: "item" }, [
        _c("span", [_vm._v("地址：深圳市南山区侨香路4068号智慧广场18楼")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copyright" }, [
      _vm._v(
        " Copyright © 2015 深圳市太和物联信息技术有限公司 All Rights Reserved "
      ),
      _c("a", { attrs: { href: "https://beian.miit.gov.cn" } }, [
        _vm._v("粤ICP备08010402号")
      ]),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://szcert.ebs.org.cn/aa4ef582-92a4-4324-b680-095b5bbeb372",
            target: "_blank"
          }
        },
        [
          _c("img", {
            attrs: {
              src: "https://szcert.ebs.org.cn/Images/govIcon.gif",
              alt: "深圳市市场监督管理局企业主体身份公示",
              title: "深圳市市场监督管理局企业主体身份公示"
            }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }