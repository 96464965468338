import {
  selectAllTaxonomy
} from '@/api/design'
import _ from 'lodash'
import SearchParamsUtil from './SearchParamsUtil'
import Router from '@/router'
import {
  queryTreeTags, queryPage,
  followAdd, followCancel, planargetone,
  userplanaradd
} from '@/api/cubeclient'
import { Image } from 'element-ui'
const selectedModelList = {}
export default {
  showImage (item) {
    this.imgsrc = item.coverImg
    this.showPreImg = true
  },
  async collectionOper (item) {
    await followAdd({
      planarId: item.id
    })
    item.follow = true
  },
  async cancelOper (item) {
    await followCancel({
      planarId: item.id
    })
    item.follow = false
  },
  async searchModel () {
    let params = SearchParamsUtil.getParams()
    const res = await queryPage({
      // taxonomyIdList:[parseInt(this.id)],
      classifications: params.selectedClassifications,
      tags: params.selectedTags.filter(item => { return item != "" }),
      styleAttrs: params.selectedStyleAttrs,
      colorAttrs: params.selectedColorAttrs,
      sort: this.sort,
      keywords: this.seachContent ? this.seachContent : '',
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      sourceId: this.userInfo.userId,
      holidays: params.selectedHolidays
    })
    this.modelList = res.data.list
    this.total = res.data.total
    const imgList = _.cloneDeep(this.modelList)
    /**
     * 瀑布流操作
     */
    const loadImage = () => {
      if (imgList.length === 0) {
        return
      }
      const imgItem = imgList.shift()
      const img = new Image()

      img.src = imgItem.coverImg
      const columnHeight = this.columnHeight
      const imgShow = this.imgShow
      img.onload = () => {
        let rate = 100 / img.naturalWidth
        let minIndex = 0
        let minValue = columnHeight[0]
        columnHeight.forEach((e, index) => {
          if (e < minValue) {
            minValue = e
            minIndex = index
          }
        })
        imgShow[minIndex].push(imgItem)
        columnHeight[minIndex] += img.naturalHeight * rate
        // eslint-disable-next-line eqeqeq
        if (imgList.length != 0) {
          loadImage()
        }
      }
    }
    loadImage()
  },
  initWaterfall () {
    this.imgShow = [
      [],
      [],
      [],
      [],
      [],
      []
    ]
    this.columnHeight = [0, 0, 0, 0, 0, 0]
  },
  async selectColor (item) {
    this.color = item.attrValue
    await SearchParamsUtil.selectColor(item.id)
    let params = SearchParamsUtil.getParams()
    this.tagList = params.tags
    this.classList = params.classifications
    this.colorList = params.colorAttrs
    this.styleList = params.styleAttrs
    this.holidays = params.holidays
    this.selectedTagList = params.selectedTags
    this.selectedClassList = params.selectedClassifications
    this.selectedHolidays = params.selectedHolidays
    this.initWaterfall()
    this.searchModel()
  },
  async selectStyle (item) {
    this.styleDesc = item.attrName

    await SearchParamsUtil.selectStyle(item.id)
    let params = SearchParamsUtil.getParams()
    this.tagList = params.tags
    this.classList = params.classifications
    this.colorList = params.colorAttrs
    this.styleList = params.styleAttrs
    this.holidays = params.holidays
    this.selectedTagList = params.selectedTags
    this.selectedClassList = params.selectedClassifications
    this.selectedHolidays = params.selectedHolidays
    this.initWaterfall()
    this.searchModel()
  },
  async selectModel (item, createUserId) {
    const modeDetailRes = await planargetone({ id: item.id })
    const { coverImg, isExquisite, planarName, planarSize, planarUnit, printFlag, psdJsonId, psdList, putAwayStatus, sort, printCalalogId } = modeDetailRes.data
    const model = { coverImg, isExquisite, planarName, planarSize, planarUnit, printFlag, psdJsonId, psdList, putAwayStatus, sort, printCalalogId }
    model.classifications = modeDetailRes.data.classifications.map(item => item.id)
    model.colorAttrs = modeDetailRes.data.colorAttrs.map(item => item.id)
    model.styleAttrs = modeDetailRes.data.styleAttrs.map(item => item.id)
    model.tags = modeDetailRes.data.tags.map(item => item.id)
    if (createUserId) {
      model.createUserId = createUserId
    } else {
      model.createUserId = this.userInfo.userId ? this.userInfo.userId : ''
    }

    model.sourcePlanarId = item.id
    const res = await userplanaradd(model)
    if (res.code === 200) {
      const { href } = Router.resolve({ name: 'PostersEdit', query: { id: res.data } })
      window.open(href, '_blank')
    } else {
      this.$message({
        message: res.msg,
        type: 'warning'
      })
    }
  },
  handleCurrentChange (value) {
    this.currentPage = value
    this.initWaterfall()
    this.searchModel()
  },
  async getAllTagList () {
    let tagList = []
    const res = await selectAllTaxonomy({ id: 10 }) // 获取标签列表
    if (res.data) {
      tagList = res.data[0].childList.map(e => {
        let childList = []
        if (!e.childList || e.childList == null) {
          e.childList = []
        }
        e.childList.forEach(item => {
          if (item.childList) {
            childList = [...childList, ...item.childList]
          }
        })
        e.childList = childList
        return e
      })
    }
    return tagList
  },
  async getSelectionId () {
    let id = 493
    const res = await selectAllTaxonomy({ id: 5 })
    this.menuList = res.data[0].childList
    this.menuList.forEach(e => {
      if (e.taxonomyName === '精选') {
        id = e.id
      }
    })
    return id
  },
  async getTagListByClassId (ids) {
    const res = await queryTreeTags({
      classifications: ids,
      parentId: 0
    })
    return res.data
  },
  getTagIdByTagList (tagList) {
    const tagIdList = []
    tagList.forEach(ele => {
      ele.selectedList = []
    })
    return tagIdList
  },
  getTaxonomyIdListByClassList (classList) {
    let result = []
    classList.forEach(child => {
      if (!child.childList || child.childList == null) {
        child.childList = []
      }
      result.push(child.id)
      const res = child.childList.map(e => {
        return e.id
      })
      result = [...result, ...res]
    })
    return result
  },
   async selectedClass (data) {
      await SearchParamsUtil.selectClassification(data[0].id)
      let params = SearchParamsUtil.getParams()
      this.tagList = params.tags
      this.classList = params.classifications
      this.colorList = params.colorAttrs
      this.styleList = params.styleAttrs
      this.holidays = params.holidays
      this.selectedTagList = params.selectedTags
      this.selectedClassList = params.selectedClassifications
      this.selectedHolidays = params.selectedHolidays
      this.initWaterfall()
      this.searchModel()
    },
     async selectedHoliday (data) {
      await SearchParamsUtil.selectedHoliday(data[0].id)
      let params = SearchParamsUtil.getParams()
      this.tagList = params.tags
      this.classList = params.classifications
      this.colorList = params.colorAttrs
      this.styleList = params.styleAttrs
      this.holidays = params.holidays
      this.selectedTagList = params.selectedTags
      this.selectedClassList = params.selectedClassifications
      this.selectedHolidays = params.selectedHolidays
      this.initWaterfall()
      this.searchModel()
    },
    async selectedTag (data) {
      await SearchParamsUtil.selectTag(data[0].id)
      let params = SearchParamsUtil.getParams()
      this.tagList = params.tags
      this.classList = params.classifications
      this.colorList = params.colorAttrs
      this.styleList = params.styleAttrs
      this.holidays = params.holidays
      this.selectedTagList = params.selectedTags
      this.selectedClassList = params.selectedClassifications
      this.selectedHolidays = params.selectedHolidays
      this.initWaterfall()
      this.searchModel()
    },
    initStyle () {
      this.colorList = []
      this.styleList = []
      this.holidays = []
      this.styleId = []
      this.colorId = []
      this.styleDesc = '全部'
      this.color = ''
    },
}
