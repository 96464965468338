<template>
  <div class="index">
      <div class="indexTop">
        <div class="leftTitle">
          <h1>创意无限 立方实现</h1>
          <h2>让设计更简单 · 让营销更有 · 让印刷更轻松</h2>
          <div @click="$router.push('/OnlineDesign/Selection')" class="start">开始使用</div>
        </div>
        <div class="rightImg">
          <video autoplay="autoplay" loop="loop" muted="muted" poster="" class="video">
            <source src="https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/video/banner1.mp4" type="video/mp4">
          </video>
        </div>
      </div>
      <div class="process">
        <p class="process-title">一站式印刷品解决方案</p>
        <img src="../../assets/img/index/process.png" alt="" class="process-banner">
        <ul class="process-list">
<!--          <li class="process-item">-->
<!--            <img src="../../assets/img/index/icon_design.png" alt="">-->
<!--            <h3>在线设计</h3>-->
<!--            <p>通过模板+编辑器，快速输出个性化精美设计稿</p>-->
<!--          </li>-->
          <li class="process-item">
            <img src="../../assets/img/index/icon_marketing.png" alt="">
            <h3>营销活动</h3>
            <p>主流的活动模板+高效的运营玩法，轻松策划一场爆款活动</p>
          </li>
          <li class="process-item">
            <img src="../../assets/img/index/icon_QR.png" alt="">
            <h3>二维码</h3>
            <p>二维码联结设计稿和营销活动，让创意与目的更有效地传播</p>
          </li>
<!--          <li class="process-item">-->
<!--            <img src="../../assets/img/index/icon_print.png" alt="">-->
<!--            <h3>在线印刷</h3>-->
<!--            <p>设计稿在线一键下单，线上与线下无缝衔接</p>-->
<!--          </li>-->
        </ul>
      </div>
      <!-- 在线设计 -->
<!--      <div class="design">-->
<!--        <h3 class="design-title">在线设计，轻松上手</h3>-->
<!--        <div class="design-content">-->
<!--          <div class="video-area">-->
<!--            <video autoplay="autoplay" loop="loop" muted="muted" poster="" class="design-video">-->
<!--              <source src="https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/video/%E6%B5%B7%E9%87%8F%E6%A8%A1%E6%9D%BF.mp4" type="video/mp4">-->
<!--            </video>-->
<!--            <ul class="design-info">-->
<!--              <li class="design-item">-->
<!--                <img src="../../assets/img/index/icon_template.png" alt="" class="design-icon">-->
<!--                <div class="design-text">-->
<!--                  <h4>海量模板</h4>-->
<!--                  <p>海量精美模板，让设计事半功倍</p>-->
<!--                </div>-->
<!--              </li>-->
<!--              <li class="design-item">-->
<!--                <img src="../../assets/img/index/icon_editor.png" alt="" class="design-icon">-->
<!--                <div class="design-text">-->
<!--                  <h4>编辑器</h4>-->
<!--                  <p>极简式编辑器，小白也能轻松上手</p>-->
<!--                </div>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--          <div class="template-area">-->
<!--            <h4 class="area-title">-->
<!--              <span class="text">海量精美模板</span>-->
<!--            </h4>-->
<!--            <div class="template-label">-->
<!--              <span class="template-page" :class="{active: pageNo > 1}" @click="nextPage('sub')">-->
<!--                <i class="el-icon-arrow-left"></i>-->
<!--              </span>-->
<!--              <ul class="template-list">-->
<!--                <li v-for="(item, index ) in templateClass" :key="index" class="template-item" @click="searchTemplate(index, item.id)" :class="{active: index === currentTemp}">{{ item.classificationName}}</li>-->
<!--              </ul>-->
<!--              <span class="template-page" :class="{active: pageNo * 8 < total}" @click="nextPage('add')">-->
<!--                <i class="el-icon-arrow-right"></i>-->
<!--              </span>-->
<!--            </div>-->
<!--            <ul class="template-content">-->
<!--              <li v-for="(item, index ) in templateList" :key="index" class="content-item selected" @click="createTemp(item.id)" @mouseover="selectTemp = index" @mouseout="selectTemp = null">-->
<!--                <img :src="item.coverImg" alt="">-->
<!--                <p>{{ item.planarName }}</p>-->
<!--                <div class="price">-->
<!--                  <span class="sellPrice free" v-if="item.price.free">免费</span>-->
<!--                  <span class="sellPrice" v-else-if="item.price.limitedTimeFree">限免</span>-->
<!--                  <span class="sellPrice" v-else>{{ '￥' + item.price.salePrice }}</span>-->
<!--                  <span class="orgPrice" v-if="!item.price.free && item.price.price">{{ '￥' + item.price.price }}</span>-->
<!--                </div>-->
<!--              </li>-->
<!--            </ul>-->
<!--            <div class="template-more" @click="$router.push('/OnlineDesign/Selection')">-->
<!--              查看更多-->
<!--              <i class="el-icon-sort-up"></i>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <!-- 营销活动 -->
      <div class="design">
        <h3 class="design-title">营销活动，高效拉新</h3>
        <div class="design-content">
          <div class="video-area">
            <video autoplay="autoplay" loop="loop" muted="muted" poster="" class="design-video">
              <source src="https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/video/%E8%90%A5%E9%94%80%E8%AE%BE%E8%AE%A1.mp4" type="video/mp4">
            </video>
            <ul class="design-info">
              <li class="design-item active">
                <img src="../../assets/img/index/icon_play.png" alt="" class="design-icon">
                <div class="design-text">
                  <h4>玩法多样化</h4>
                  <p>多样化的活动玩法，可玩性高</p>
                </div>
              </li>
              <li class="design-item active">
                <img src="../../assets/img/index/icon_rules.png" alt="" class="design-icon">
                <div class="design-text">
                  <h4>自定义规则</h4>
                  <p>可视化自定义活动规则，高效拉新促活</p>
                </div>
              </li>
              <li class="design-item active">
                <img src="../../assets/img/index/icon_monitoring.png" alt="" class="design-icon">
                <div class="design-text">
                  <h4>可视化监控</h4>
                  <p>提供主流的电子化奖品，自动发奖，活动数据全程监控</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="template-area">
            <h4 class="area-title">
              <span class="text">多样化的玩法，丰富的活动模板</span>
            </h4>
            <div class="template-label">
              <ul class="template-list">
                <li v-for="(item, index ) in prizeTypeList" :key="index" class="template-item" @click="searchActive(index, item.value)" :class="{active: index === currentActive}">{{ item.label }}</li>
              </ul>
            </div>
            <ul class="template-content">
              <li v-for="(item, index ) in activeList" :key="index" class="content-item selected" @click="createAct(item.id)" @mouseover="selectAct = index" @mouseout="selectAct = null">
                <img :src="item.cover" alt="">
                <p>{{ item.name }}</p>
                <div class="price">
                  <span class="sellPrice free" v-if="item.isFree">免费</span>
                  <span class="sellPrice" v-else-if="item.templeStatus == 1">限免</span>
                  <span class="sellPrice" v-else>{{ '￥' + item.sellingPrice }}</span>
                  <span class="orgPrice" v-if="!item.isFree && item.originalPrice">{{ '￥' + item.originalPrice }}</span>
                </div>
              </li>
            </ul>
            <div class="template-more" @click="$router.push('/MarketingActivities')">
              查看更多
              <i class="el-icon-sort-up"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- 在线印刷 -->
      <div class="design">
        <h3 class="design-title">在线印刷，物美价廉</h3>
        <div class="design-content">
          <div class="video-area">
            <video autoplay="autoplay" loop="loop" muted="muted" poster="" class="design-video">
              <source src="https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/video/%E5%9C%A8%E7%BA%BF%E5%8D%B0%E5%88%B7.mp4" type="video/mp4">
            </video>
            <ul class="design-info">
              <li class="design-item active">
                <img src="../../assets/img/index/icon_print.png" alt="" class="design-icon">
                <div class="design-text">
                  <h4>印品类型丰富</h4>
                  <p>丰富的印品类型，满足多种印刷场景</p>
                </div>
              </li>
              <li class="design-item active">
                <img src="../../assets/img/index/icon_quality.png" alt="" class="design-icon">
                <div class="design-text">
                  <h4>品质保障</h4>
                  <p>大厂承接订单，保证物美价廉、售后无忧</p>
                </div>
              </li>
              <li class="design-item active">
                <img src="../../assets/img/index/icon_order.png" alt="" class="design-icon">
                <div class="design-text">
                  <h4>全程自助下单</h4>
                  <p>自定义自助下单和帮我下单，让印刷更轻松</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="template-area">
<!--            <h4 class="area-title">-->
<!--              <span class="text">热门印品</span>-->
<!--            </h4>-->
<!--            <ul class="template-content">-->
<!--              <li v-for="(item, index ) in printList" :key="index" class="content-item selected print-item" @click="createPrint(item)" @mouseover="selectPrint = index" @mouseout="selectPrint = null">-->
<!--                <img :src="`https://${JSON.parse(item.describe).picture[0]}`" alt="" v-if="item.describe">-->
<!--                <p>{{ item.spuName }}</p>-->
<!--              </li>-->
<!--            </ul>-->
<!--            <div class="template-more" @click="$router.push('/OnlinePrinting')">-->
<!--              查看更多-->
<!--              <i class="el-icon-sort-up"></i>-->
<!--            </div>-->
          </div>
        </div>
      </div>
      <div class="comment">
        <h3>优秀的口碑源于对品质的追求</h3>
        <div class="slideshow">
          <el-carousel indicator-position="outside" height="180px">
            <el-carousel-item v-for="(item, index) in commentList" :key="index">
              <ul class="comment-list">
                <li class="comment-item" v-for="(value, key) in item" :key="key">
                  <div class="comment-title">
                    <img :src="value.img" alt="" width="45px" height="45px">
                    <p class="name">{{ value.name }}</p>
                    <p class="post">{{ value.post }}</p>
                  </div>
                  <p class="describe">{{ value.describe }}</p>
                </li>
              </ul>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="index-bottom">
        <div class="left-text">
          <h1>创意无限 立方实现</h1>
          <p>让设计更简单 · 让营销更有料 · 让印刷更轻松</p>
        </div>
        <div class="right-btn" @click="$router.push('/OnlineDesign/Selection')">开始使用</div>
      </div>
      <div class="footer">
        <div class="introduce">
          <dl class="companyInfo">
            <dt>
              <img src="../../assets/img/index/logo_bottom.png" alt="">
            </dt>
            <dd class="item" @click="join()" v-if="show">
              <img src="../../assets/img/index/icon_factory.png" alt="">
                印刷厂入驻
            </dd>
          </dl>
          <dl class="area">
            <dt class="item">
              <h4>无限立方</h4>
            </dt>
            <dd class="item">
              <a href="http://taiheiot.com/about/introduction" target="_blank">公司介绍</a>
            </dd>
            <dd class="item">
              <a href="http://taiheiot.com/about/honor" target="_blank">企业荣誉</a>
            </dd>
            <dd class="item">
              <a href="http://taiheiot.com/about/join" target="_blank">加入我们</a>
            </dd>
          </dl>
          <dl class="area">
            <dt class="item">
              <h4>产品服务</h4>
            </dt>
<!--            <dd class="item">-->
<!--              <a :href="`${$url}/#/OnlineDesign/Selection`" target="_blank">在线设计</a>-->
<!--            </dd>-->
            <dd class="item">
              <a :href="`${$url}/#/MarketingActivities`" target="_blank">营销活动</a>
            </dd>
<!--            <dd class="item">-->
<!--              <a :href="`${$url}/#/OnlinePrinting`" target="_blank">在线印刷</a>-->
<!--            </dd>-->
          </dl>
          <dl class="area">
            <dt class="item">
              <h4>帮助中心</h4>
            </dt>
            <dd class="item">
              <a href="javascript:void(0)" >帮助文档</a>
            </dd>
            <dd class="item">
              <a :href="`${$url}/#/PrivacyAgreement`"  target="_blank">隐私策略</a>
            </dd>
            <dd class="item">
              <a :href="`${$url}/#/UserAgreement`"  target="_blank">用户协议</a>
            </dd>
          </dl>
          <dl class="area">
            <dt class="item">
              <h4>联系我们</h4>
            </dt>
            <dd class="item">
              <span>电话：86-755-86098666</span>
            </dd>
            <dd class="item">
              <span>传真：86-755-86011222</span>
            </dd>
            <dd class="item">
              <span>地址：深圳市南山区侨香路4068号智慧广场18楼</span>
            </dd>
          </dl>
        </div>
      </div>
      <div class="copyright">
        Copyright © 2015 深圳市太和物联信息技术有限公司 All Rights Reserved <a href="https://beian.miit.gov.cn">粤ICP备08010402号</a>
        <a href="https://szcert.ebs.org.cn/aa4ef582-92a4-4324-b680-095b5bbeb372" target="_blank">
          <img src="https://szcert.ebs.org.cn/Images/govIcon.gif" alt="深圳市市场监督管理局企业主体身份公示" title="深圳市市场监督管理局企业主体身份公示">
        </a>
      </div>
      <!-- 侧边栏 -->
      <div class="sidebar">
        <ul>
          <li class="sidebar-item" v-for="(item, index) in sidebarList" :key="index" @mouseover="current = index"
            @mouseout="current = null">
            <template v-if="index != 1 || isShow">
              <a :href="item.href" @click="toTop(index)">
                <img :src="item.icon" :title="item.title">
                <img :src="item.icon_selected" :title="item.title" :class="['over', {active: index === current}]">
              </a>
            </template>
          </li>
        </ul>
        <div class="qrcode" v-if="current === 0">
          <p class="qrcode-text">无限立方公众号</p>
          <img src="../../assets/infinite_official_account.jpg" alt="" class="public" title="无限立方公众号">
          <p class="qrcode-text">客服二维码</p>
          <img src="../../assets/wxkf.jpg" alt="" class="public" title="客服二维码">
        </div>
      </div>
     <bind></bind>
  </div>
</template>

<script>
import { getLocal, setLocal } from '@/lib/local'
import { getTemplateClass, getTemplateList, getPrintList, getActiveList } from '@/api/index'
import bind from '../../modules/bind.vue'
import { getRoleIds } from '@/lib/util.js'
import DesignClassUtil from '@/views/OnlineDesign/common/DesignClassUtil.js'
import { mapState, mapMutations } from 'vuex'
export default {
  data () {
    return {
      // show: false,
      tipsBind: this.$route.params.tipsBind || false, // QQ登录提示绑定手机号,
      templateClass: [],
      templateList: [],
      printList: [],
      activeList: [],
      currentTemp: 0,
      currentActive: 0,
      selectTemp: null,
      selectAct: null,
      selectPrint: null,
      pageNo: 1,
      total: 0,
      prizeTypeList: [
        {
          value: 0,
          label: '开宝箱'
        },
        {
          value: 1,
          label: '九宫格'
        },
        {
          value: 2,
          label: '砸金蛋'
        },
        {
          value: 3,
          label: '大转盘'
        },
        // {
        //   value: 4,
        //   label: '小兔兔奔月记'
        // },
        // {
        //   value: 5,
        //   label: '红旗护卫'
        // },
        // {
        //   value: 6,
        //   label: '画金蛋'
        // },
        // {
        //   value: 7,
        //   label: '幸运大翻转'
        // },
        // {
        //   value: 8,
        //   label: '欢乐抽奖机'
        // },
        {
          value: 9,
          label: '阅读有礼'
        }
      ],
      commentList: [
        [
          {
            img: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/8e879102365043be961503d9d55b795e.jpg',
            name: '患者无药',
            post: '自媒体人',
            describe: '用这个做海报真的很容易简单，元素随便拖一拖，还设计得那么好看，好评！！'
          },
          {
            img: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/246e3bb83a434579822e3a0cda636f60.jpg',
            name: '铭',
            post: '新媒体运营',
            describe: '我觉得我用无限立方做的海报，比淘宝上和其他平台做的，好看多了。'
          },
          {
            img: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/c2c75982966d4e6f96f4849907021cf7.jpg',
            name: '无二',
            post: '行政经理',
            describe: '营销活动拉新效果简直不要太好！！！谁试谁知道！！'
          },
          {
            img: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/17da784bd89d4976bb8a31f1ad9f5831.jpg',
            name: '半寸时光',
            post: '商户老板',
            describe: '收藏了，这个网站很不错，印刷的物品种类多，关键是质量真的好。'
          },
        ],
        [
          {
            img: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/460ca126273d4ebd970013f6d63d568f.jpg',
            name: '梦莹几度',
            post: '自媒体人',
            describe: '作为一个新媒体小编，自己用着挺好。设计模板多，更新快，效果nice~'
          },
          {
            img: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/19f1e4c1835642a2b14a8366fc58e138.jpg',
            name: '四月',
            post: '公众号运营',
            describe: '无限立方真是个好东西！以后我也能自己搞设计啦！开心到飞起啊哈哈'
          },
          {
            img: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/79d34ac78ace45e2b7fd459083d54d19.jpg',
            name: '那一天',
            post: '市场营销',
            describe: '营销活动的模板好好看！而且还有玩法，开一场活动我就换一个模板，真的爽！'
          },
          {
            img: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/22b53453a4df47449da61649536fefd2.jpg',
            name: '春暖花开',
            post: '商务主管',
            describe: '又能设计又能印刷，这也太方便了吧！爆赞！！'
          },
        ],
        [
          {
            img: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/38c12f4269294d42b6047d6fdaff4f6c.jpg',
            name: '安稳的日子',
            post: '自媒体人',
            describe: '真心希望无限立方越来越好，在线制图比专门打开ps真的方便太多了！os：ps真的难用..'
          },
          {
            img: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/5f9cf96e47864905b6e9a10d0d5e0149.jpg',
            name: '纸玫瑰',
            post: '人事经理',
            describe: '好用，感觉以后会成为我主要的做图平台。完美。 界面简洁，快速做图。 节省了很多时间呢'
          },
          {
            img: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/0bb00fb8ed554ca0aec56a830de75fad.jpg',
            name: '梦',
            post: '自媒体人',
            describe: '深有体会，公司临时急需一张海报宣传，还要我出图？！我又不是设计师，幸亏无限立方在线设计救了我！太感动了！！'
          },
          {
            img: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/412ce1c355684bd2ab8b55d4e7ec18a2.jpg',
            name: '眼眸',
            post: '市场营销',
            describe: '在线印刷咨询的客服小姐姐好温柔~很耐心的跟我说下单教程，哭了！还有，印刷品真的确实不错，已收到货啦~~'
          },
        ],
      ],
      sidebarList: [
        // {
        //   title: '优惠卷',
        //   href: '#',
        //   icon: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/ef8972483e12471f82d15aadb09582f4.png',
        //   icon_selected: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/288e592fb6ba40698ca2807009bbd1df.png'
        // },
        {
          title: '客服',
          href: 'javascript:void(0);',
          icon: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/7ecd31aacc8b4d2e8a54fe4c70037592.png',
          icon_selected: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/0127e6b2ec8548249c93ad5dbc7a3554.png'
        },
        // {
        //   title: '帮助中心',
        //   href: '#',
        //   icon: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/689e89b0860a4e438dd837d4c2a26840.png',
        //   icon_selected: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/0ec566c1527d43f3ae22b16fc26cc73d.png'
        // },
        {
          title: '返回顶部',
          href: 'javascript:void(0);',
          icon: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/43e88716fe454dc4a1d902bee5d3eb9f.png',
          icon_selected: 'https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com/upload/20210810/1448ce217a7a4ee8bf1b2bd741601a3b.png'
        },
      ],
      current: null,
      isShow: false
    }
  },
  components: {
    bind
  },
  computed: {
    ...mapState(['show'])
  },
  created () {
	if(this.$route.query.token){
		setLocal(this.$route.query.token, 'token')
	}
	this.setUserInfo()
	if(this.$route.query.systemCode){
		setLocal(this.$route.query.systemCode, 'systemCode')
		this.setSystemCode(this.$route.query.systemCode)
	}
    if(!getLocal()) {
      // this.show = true
      this.setShow(true)
    } else {
      this.setShow(false)
      // this.show = false
    }
    this.getTemplate()
    this.getPrint()
    this.getActive(0)
    this.$bus.$on('backShow', (isShow) => {
      this.isShow = isShow
    })

  },
  mounted () {
	if(this.$route.query.path){
		switch(this.$route.query.path){
			case 'marketingActivity/OwnActivity':
				this.$router.push(this.$route.query.path)
				break
			case 'MarketingActivityEdit':
				this.$router.push({
					name: 'MarketingActivityEdit',
					query:{
						activityId: this.$route.query.activityId,
						templeName: this.$route.query.templeName
					}
				})
				break
			case 'ActivityData':
				this.$router.push({
					path: 'ActivityData/' + this.$route.query.status,
					query:{
						activityId: this.$route.query.activityId,
						tab: this.$route.query.tab
					}
				})
				break
			default:
				this.$router.push(this.$route.query.path)
		}
	}
    if(this.tipsBind) {
      this.$bus.$emit('getInfo')
    }
  },
  beforeDestroy () {
    this.$bus.$off('getInfo')
  },
  methods: {
	...mapMutations(['setUserInfo','setShow']),
	...mapMutations('market',['setSystemCode']),
    toTop (index) {
      if(index === 1) {
        document.documentElement.scrollTop = 0
      }
    },
    join () {
      if(!getLocal()) {
        this.$router.push({name: 'PrintingJoin'})
      }
      // else {
      //   this.$message('请先退出当前用户！')
      // }
    },
    // 获取模板分类
    async getTemplate () {
      const res = await getTemplateClass({level: 2, pageNo: this.pageNo, pageSize: 8})
      if(res.code === 200) {
        this.templateClass = res.data.list
        this.total = res.data.total
        this.getList([this.templateClass[0].id])
      }
    },
    // 下一页/上一页
    nextPage (sub) {
      if(sub == 'sub' && this.pageNo > 1) {
        this.currentTemp = 0
        this.pageNo--
        this.getTemplate()
      }else if (sub == 'add' && this.pageNo * 8 < this.total) {
        this.currentTemp = 0
        this.pageNo++
        this.getTemplate()
      }
    },
    // 获取模板列表
    async getList (classifications) {
      const res = await getTemplateList({classifications, pageNo: 1, pageSize: 10})
      if(res.code === 200) {
        this.templateList = res.data.list
      }
    },
    // 按分类查模板
    searchTemplate (index, id) {
      this.currentTemp = index
      this.getList([id])
    },
    // 按活动类型查询
    searchActive (index, type) {
      this.currentActive = index
      this.getActive(type)
    },
    // 获取印刷列表
    async getPrint () {
      const res = await getPrintList({pageNo: 1, pageSize: 5})
      if(res.code === 0) {
        this.printList = res.data.list
      }
    },
    // 获取活动列表
    async getActive (type) {
      const res = await getActiveList({pageNo: 1, pageSize: 5, userId: getRoleIds().userId ? getRoleIds().userId : '', type})
      if(res.code === 200) {
        this.activeList = res.data.list
      }
    },
    // 创建活动
    createAct (id) {
      sessionStorage.removeItem('data')
      const { href } = this.$router.resolve({ path: '/MarketingActivityEdit', query: { id: id } })
      window.open(href, '_blank')
    },
    // 创建模板
    createTemp (id) {
      DesignClassUtil.selectModel({ id }, getRoleIds().userId ? getRoleIds().userId : '')
    },
    // 印刷订单
    createPrint (item) {
      const { href } = this.$router.resolve({ name: 'PoductDetail', query: { spuId: item.id, taxonomy: item.classifications } })
      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  .index {
    .indexTop {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 680px;
      background: url("../../assets/img/index/banner_bg.png");
      padding-top: 120px;
      margin: 0 auto;
      color: #fff;
      .leftTitle {
        padding: 60px 45px 0 0;
        h1 {
          font-size: 42px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #FFFFFF;
        }
        h2 {
          margin: 20px 0 80px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
        }
        .start {
          width: 200px;
          height: 60px;
          text-align: center;
          line-height: 60px;
          background: linear-gradient(90deg, #F8AE1B, #FDE34F);
          border-radius: 4px;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #124686;
          cursor: pointer;
        }
      }
      .rightImg {
        .video {
          width: 620px;
          height: 380px;
          border-radius: 8px;
        }
      }
    }
    .process {
      width: 1000px;
      margin: 80px auto 120px;
      .process-title {
        text-align: center;
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #181818;
        margin-bottom: 30px;
      }
      .process-banner {
        margin-bottom: 50px;
      }
      .process-list {
        display: flex;
        justify-content: space-around;
        .process-item {
          width: 20%;
          text-align: center;
          h3 {
            font-size: 18px;
            font-family: Source Han Sans CN;
            color: #181818;
            margin-bottom: 10px;
          }
          img {
            width: 50px;
          }
          p {
            font-size: 14px;
            color: #666;
          }
        }
      }
    }
    .introduce {
      height: 150px;
      width: 1200px;
      display: flex;
      justify-content: space-between;
      a {
        text-decoration: none;
      }
      .area {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
        .item {
          text-align: left;
          a,
          span {
            color: #888888;
            font-size: 14px;
          }
          h4 {
            color: #BABABA;
          }
        }
      }
      .companyInfo {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
        color: #666;
        font-size: 14px;
        .item {
          position: relative;
          width: 118px;
          height: 28px;
          background: #313131;
          border: 1px solid #181818;
          line-height: 28px;
          text-align: center;
          border-radius: 2px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #BABABA;
          cursor: pointer;
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #313131;
      height: 240px;
    }
    .copyright {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #666;
      height: 64px;
      background-color: #181818;
      img {
        width: 20px;
        vertical-align: bottom;
        cursor: pointer;
        margin-left: 5px;
        line-height: auto;
      }
      a {
        color: #666;
      }
    }
    .design {
      width: 1000px;
      margin: 0 auto;
      .design-title {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #181818;
        margin: 120px 0 48px 0;
        text-align: center;
      }
      .design-content {
        // 设计视频区域
        .video-area {
          display: flex;
          .design-video {
            width: 600px;
            height: 320px;
            background: #F2F6FE;
            border-radius: 4px;
          }
          .design-info {
            height: 320px;
            margin-left: 70px;
            .design-item {
              height: 160px;
              display: flex;
              align-items: center;
              .design-icon {
                width: 54px;
                height: 54px;
              }
              .design-text {
                margin-left: 20px;
                h4 {
                  font-size: 20px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  color: #181818;
                  margin-bottom: 5px;
                }
                p {
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #666666;
                }
              }
            }
            .active {
              height: 106px;
            }
          }
        }
        // 模板区域
        .template-area {
          margin-top: 50px;
          .area-title {
            text-align: center;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #181818;
            .text {
              box-shadow: 0 6px #99c2ff;
              display: inline-block;
              height: 22px;
            }
          }
          .template-label {
            display: flex;
            width: 100%;
            margin-top: 30px;
            .template-page {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background-color: #e5e5e5;
              color: #fff;
              font-size: 14px;
              text-align: center;
              line-height: 30px;
              cursor: pointer;
            }
            .active {
              background-color: #3C74F1;
            }
            .template-list {
              flex: 1;
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin: 0 50px;
              .template-item {
                padding: 7px 10px;
                background: #F3F4F8;
                border-radius: 15px;
                text-align: center;
                font-size: 14px;
                color: #313131;
                cursor: pointer;
              }
              .active {
                color: #3C74F1;
              }
            }
          }
          .template-content {
            display: flex;
            flex-wrap: wrap;
            margin-top: 30px;
            .content-item {
              position: relative;
              width: 184px;
              height: 320px;
              border-radius: 4px;
              margin-right: 20px;
              margin-bottom: 20px;
              cursor: pointer;
              transition: all .2s linear;
              &:hover{
                box-shadow: 0 4px 12px 0 rgba(0,0,0,.08);
                transform: translate3d(0,-4px,0);
              }
              &:nth-child(5n) {
                margin-right: 0;
              }
              img {
                width: 100%;
                height: 248px;
              }
              p {
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                margin-top: 15px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 700;
                color: #181818;
                padding-left: 5px;
              }
              .price {
                padding-left: 5px;
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 700;
                color: #FFFFFF;
                margin: 5px 0 10px;
                .sellPrice {
                  color: red;
                  margin-right: 5px;
                  font-size: 14px;
                }
                .orgPrice {
                  color: #ccc;
                  text-decoration: line-through;
                }
                .free {
                  padding: 2px 6px;
                  background: linear-gradient(60deg, #B5BBCF, #9CA4BC);
                  border-radius: 2px;
                  line-height: 20px;
                  text-align: center;
                  font-size: 12px;
                  color: #fff;
                }
              }
            }
            .print-item {
              height: 300px;
            }
          }
        }
        // 更多
        .template-more {
          width: 170px;
          height: 50px;
          margin: 50px auto 120px;
          background: linear-gradient(90deg,#3C74F1, #81AFD6);
          box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
          border-radius: 4px;
          line-height: 50px;
          text-align: center;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;
          i {
            font-weight: 700;
            transform: rotateZ(90deg) rotateY(180deg);
          }
        }
      }
    }
    .comment {
      width: 100%;
      height: 340px;
      background: url('../../assets/img/index/comments_bg.png');
      background-size: 100% 100%;
      padding: 20px 0;
      margin-bottom: 120px;
      h3 {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
      }
      .slideshow {
        margin-top: 40px;
      }
      .comment-list {
        display: flex;
        justify-content: space-between;
        width: 1040px;
        margin: 0 auto;
        .comment-item {
          width: 250px;
          height: 160px;
          padding: 25px 15px;
          background-color: #fff;
          .comment-title {
            height: 40%;
            img {
              float: left;
              border-radius: 50%;
            }
            p {
              margin-left: 60px;
            }
            .name {
              color: #181818;
              font-weight: 700;
            }
            .post {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666666;
              margin-top: 5px;
            }
          }
          .describe {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #181818;
            margin-top: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .index-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 1032px;
      height: 160px;
      background: url('../../assets/img/index/bottom_bg.png');
      background-size: 100% 100%;
      margin: 120px auto;
      padding: 0 50px;
      .left-text {
        h1 {
          font-size: 40px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #FFFFFF;
        }
        p {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          margin-top: 10px;
        }
      }
      .right-btn {
        width: 200px;
        height: 60px;
        background: #FFFFFF;
        border-radius: 4px;
        line-height: 60px;
        text-align: center;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #3C74F1;
        cursor: pointer;
      }
    }
    // 侧边栏
    .sidebar {
      position: fixed;
      right: 10px;
      bottom: 30vh;
      z-index: 10;
      img {
        width: 45px;
      }
      .over {
        display: none;
        position: absolute;
        left: 0;
        z-index: 20;
      }
      .active {
        display: inline-block;
      }
      .qrcode {
        position: absolute;
        left: -180px;
        top: -10px;
        z-index: 20;
        img {
          width: 150px;
        }
        .qrcode-text {
          background-color: #fff;
          text-align: center;
          font-size: 12px;
          text-align: center;
          color: #666;
        }
      }
    }
  }
</style>
